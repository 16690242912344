@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,700;0,800;0,900;1,400&display=swap');


#root{
--mainText: #131a26;

}

* {
  padding: 0;
  margin: 0;

}



body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3{
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: var(--mainText);
}

p{
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  color: var(--mainText);;
}
