.hero-section {
  width: 100%;

  background-image: url('./images/guillherme-schneider-CX6-_wXxOsY-unsplash.jpg');
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top , .background {
  height: 400px;
  width: 100%;

}

.top {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.418);
}

.top > p ,
.top > h1 {
  color: #fafafa;
}

.top > h1 {
  font-size: 3rem;
}


.content-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

a,
a:visited{
  color: var(--mainText);
  text-decoration: none;
}

p{
  text-align: center;
}
