@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,700;0,800;0,900;1,400&display=swap);
#root{
--mainText: #131a26;

}

* {
  padding: 0;
  margin: 0;

}



body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3{
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: var(--mainText);
}

p{
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  color: var(--mainText);;
}

.hero-section {
  width: 100%;

  background-image: url(/static/media/guillherme-schneider-CX6-_wXxOsY-unsplash.0944de2b.jpg);
  background-size: cover;
  background-position: center;
  height: 400px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.top , .background {
  height: 400px;
  width: 100%;

}

.top {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.418);
}

.top > p ,
.top > h1 {
  color: #fafafa;
}

.top > h1 {
  font-size: 3rem;
}


.content-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

a,
a:visited{
  color: var(--mainText);
  text-decoration: none;
}

p{
  text-align: center;
}

.card-image{
  max-width: 100%;
    border: 1px solid rgb(82, 82, 82);
}

.card-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 50%;
  margin-top: 4rem;

}

.card-container > h2 {
  margin-top: 2rem;
}

.card-title{
  font-weight: 400;
  text-align: center;
}

.card-link > a{
  font-weight: 300;
  color: rgb(14, 117, 98)
}

