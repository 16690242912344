.card-image{
  max-width: 100%;
    border: 1px solid rgb(82, 82, 82);
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 4rem;

}

.card-container > h2 {
  margin-top: 2rem;
}

.card-title{
  font-weight: 400;
  text-align: center;
}

.card-link > a{
  font-weight: 300;
  color: rgb(14, 117, 98)
}
